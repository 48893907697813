import React, { Component } from 'react'

class Files extends Component {
  state = {
    selected: []

  }
  _toggleFile = (file) => {
    const { selected } = this.state
    if(selected.filter(s=>s.id === file.id).length>0){
      this.setState({ selected: selected.filter(s => s.id !== file.id) })
    }else{
      this.setState({ selected: selected.concat(file) })
    }
  }

  render() {
    const { display, download, files, setGallery, category, deleteFile } = this.props
    const { selected, list } = this.state
    if(category && !display) return null
    return (
      <div className={`mediagrid ${display?'visible':'hidden'}`}>
        {files && files.filter((f,i) => category !== 'files' ? i < 9:true).map((file, index) =>
          <div key={index} className={`file ${selected.filter(s=>s.id === file.id).length>0 ? 'active':''}`}>
            <img className="image" onClick={() => setGallery({file: file.id, files: files})} src={file.base64} onMouseOver={e => {if(file.gif) e.currentTarget.src = file.gif}} onMouseOut={e => {if(file.gif) e.currentTarget.src = file.base64}} alt="Preview" />
            {category === 'files' ? <div className={`checkbox ${selected.filter(s=>s.id === file.id).length>0?'active':'inactive'}`} onClick={() => this._toggleFile(file)}></div>
             : <img className="checkbox fileoptions" src={'/images/basic/ThreePointsWhite.svg'} onClick={(e) => {e.stopPropagation();this.setState({ list: index })}} alt="options" />}
            {category !== 'files' && list === index &&
              <div onMouseLeave={() => this.setState({list: false})} className="quickmenu">
                <div onClick={(e) => {e.stopPropagation();setGallery({file: file.id, files: files})}}><div><img src={'/images/basiced/OpenGray.svg'} alt="Open" /></div><span>Open</span></div>
                <div onClick={(e) => {e.stopPropagation();download([file]);this.setState({list: false})}}><div><img src={'/images/basiced/DownloadGray.svg'} alt="Open" /></div><span>Download</span></div>
                <div onClick={(e) => {e.stopPropagation();deleteFile([file]);this.setState({list: false})}}><div><img src={'/images/basiced/DeleteGray.svg'} alt="Open" /></div><span>Delete</span></div>
              </div>
            }
            {file.gif && <img className="camera" src="/images/basic/CameraWhite.svg" alt="camera" />}
            {file.gif && <span className="duration">{new Date(file.duration * 1000).toISOString().substr(14, 5)}</span>}
          </div>
        )}
        {files.length < 20 && Array(20-files.length).fill(null).filter((f,i) => category !== 'files' ? i < 9-files.length:true).map((empty, index) =>
          <div key={index} className="file empty">
            <img className="image" src="/images/files/MediaEmpty.png" alt="Preview" />
          </div>
        )}
        {selected.length > 0 && category === 'files' &&
          <div className="options">
            <div onClick={() => {download(selected);this.setState({ selected: [] })}} className="download">
              <img src={'/images/basic/DownloadWhite.svg'} alt="download" />
              <span>Download</span>
            </div>
            <div onClick={() => {deleteFile(selected);this.setState({ selected: [] })}} className="delete">
              <img src={'/images/basic/TrashWhite.svg'} alt="remove"/>
              <span>Delete</span>
            </div>
          </div>
        }
      </div>
    )
  }
}
export default Files
