import React from 'react'
import Tour from 'reactour'

export const Gothrough = ({ isTourOpen, setOpen }) => {
  return (
    <Tour
      disableDotsNavigation
      steps={steps}
      maskSpace={0}
      rounded={20}
      showCloseButton={false}
      maskClassName="jjaa"
      className="gothrough"
      prevButton={(<div className="darkButton">Prev</div>)}
      nextButton={(<div className="darkButton">Next</div>)}
      lastStepNextButton={(<div className="darkButton">Finish!</div>)}
      showNumber={false}
      showNavigation={false}
      isOpen={isTourOpen}
      onRequestClose={() => setOpen()} />
  )
}

const steps = [
  {
    selector: '[data-tour="projects"]',
    content: () => (
      <>
        <div className="top">
          <img src={`/images/dark/Project.svg`} alt=""/>
        </div>
        <span className="title">Projects</span>
        <span className="description">Hey, this is your project area and this is your Test Project filled up with content, so you could see Edworking in actual work.</span>
      </>
    ),
    style: {
      backdropFilter: 'blur(5px)',
      borderRadius: 16,
      color: '#ffffff',
      backgroundColor: 'rgba(9,50,58,0.85)',
    },
  },
  {
    selector: '[data-tour="inquiries"]',
    content: () => (
      <>
        <div className="top">
          <img src={`/images/dark/Inquiries.svg`} alt=""/>
        </div>
        <span className="title">Chat & Inquiries</span>
        <span className="description">Hey, this is your chat and inquiries area. Here you can manage your chats and accept, manage, decline offers from the cilents.</span>
      </>
    ),
    style: {
      backdropFilter: 'blur(5px)',
      borderRadius: 16,
      color: '#ffffff',
      backgroundColor: 'rgba(9,50,58,0.85)',
    },
  },
/*  {
    selector: '[data-tour="mostactives"]',
    content: () => (
      <>
        <div className="top">
          <img src={`/images/dark/Mostactive.svg`} alt=""/>
        </div>
        <span className="title">Most active Chats</span>
        <span className="description">Hey, most active appear when you receive new messages in your tasks and project.</span>
      </>
    ),
    style: {
      borderRadius: 16,
      color: '#ffffff',
      backgroundColor: 'rgba(9,50,58,0.85)',
    },
  },*/
  {
    selector: '[data-tour="tasks"]',
    content: () => (
      <>
        <div className="top">
          <img src={`/images/dark/Tasks.svg`} alt=""/>
        </div>
        <span className="title">Tasks</span>
        <span className="description">Hey, here you can see the created tasks, manage them and create some new ones!</span>
      </>
    ),
    style: {
      backdropFilter: 'blur(5px)',
      borderRadius: 16,
      color: '#ffffff',
      backgroundColor: 'rgba(9,50,58,0.85)',
    },
  },
  {
    selector: '[data-tour="stories"]',
    content: () => (
      <>
        <div className="top">
          <img src={`/images/dark/Stories.svg`} alt=""/>
        </div>
        <span className="title">Stories</span>
        <span className="description">Hey, here you can create the usual stories (short videos) and share them in your tasks to show some issues accuring…</span>
      </>
    ),
    style: {
      backdropFilter: 'blur(5px)',
      borderRadius: 16,
      color: '#ffffff',
      backgroundColor: 'rgba(9,50,58,0.85)',
    },
  },
  {
    selector: '[data-tour="files"]',
    content: () => (
      <>
        <div className="top">
          <img src={`/images/dark/Files.svg`} alt=""/>
        </div>
        <span className="title">Files</span>
        <span className="description">Hey, here you can upload and share any files or images with your team.</span>
      </>
    ),
    style: {
      backdropFilter: 'blur(5px)',
      borderRadius: 16,
      color: '#ffffff',
      backgroundColor: 'rgba(9,50,58,0.85)',
    },
  },
  {
    selector: '[data-tour="consults"]',
    content: () => (
      <>
        <div className="top">
          <img src={`/images/dark/Consults.svg`} alt=""/>
        </div>
        <span className="title">Consults</span>
        <span className="description">Hey, in Edworking you can ask questions and get help from our community if freelancers and proffesionals all around the world.</span>
      </>
    ),
    style: {
      backdropFilter: 'blur(5px)',
      borderRadius: 16,
      color: '#ffffff',
      backgroundColor: 'rgba(9,50,58,0.85)',
    },
  },
  {
    selector: '[data-tour="edworkers"]',
    content: () => (
      <>
        <div className="top">
          <img src={`/images/dark/Edworkers.svg`} alt=""/>
        </div>
        <span className="title">Edworkers</span>
        <span className="description">Hey, in Edworking you can hire top class engeneers, designers and other proffesionals.</span>
      </>
    ),
    style: {
      backdropFilter: 'blur(5px)',
      borderRadius: 16,
      color: '#ffffff',
      backgroundColor: 'rgba(9,50,58,0.85)',
    },
  },
  {
    selector: '[data-tour="workspace"]',
    content: () => (
      <>
        <div className="top">
          <img src={`/images/dark/Workspace.svg`} alt=""/>
        </div>
        <span className="title">Workspace</span>
        <span className="description">Hey, this is the Workspace, the area where you can text your documentation and edit that live with your team.</span>
      </>
    ),
    style: {
      backdropFilter: 'blur(5px)',
      borderRadius: 16,
      color: '#ffffff',
      backgroundColor: 'rgba(9,50,58,0.85)',
    },
  },
  {
    selector: '[data-tour="meetingroom"]',
    content: () => (
      <>
        <div className="top">
          <img src={`/images/dark/Meetingroom.svg`} alt=""/>
        </div>
        <span className="title">Meetingroom</span>
        <span className="description">Hey, here you can start your personal video session with other members of your team.</span>
      </>
    ),
    style: {
      backdropFilter: 'blur(5px)',
      borderRadius: 16,
      color: '#ffffff',
      backgroundColor: 'rgba(9,50,58,0.85)',
    },
  },
  {
    selector: '[data-tour="latestupdates"]',
    content: () => (
      <>
        <div className="top">
          <img src={`/images/dark/LatestUpdates.svg`} alt=""/>
        </div>
        <span className="title">Latest Updates</span>
        <span className="description">Hey, here you can see your team members activity and have quick access to the updates!</span>
      </>
    ),
    style: {
      backdropFilter: 'blur(5px)',
      borderRadius: 16,
      color: '#ffffff',
      backgroundColor: 'rgba(9,50,58,0.85)',
    },
  },
  {
    selector: '[data-tour="promotions"]',
    content: () => (
      <>
        <div className="top">
          <img src={`/images/dark/Promotions.svg`} alt=""/>
        </div>
        <span className="title">Promotions</span>
        <span className="description">Hey, you can invite your friends and teammates and get real money in reward!.</span>
      </>
    ),
    style: {
      backdropFilter: 'blur(5px)',
      borderRadius: 16,
      color: '#ffffff',
      backgroundColor: 'rgba(9,50,58,0.85)',
    },
  },
]
