import React, { Component } from 'react'
import { srcType, timeDiff, newLive, capitalizeFirstLetter } from 'js/utils'

class Documents extends Component {
  state = {
    delete: false,
    selected: [],
    list: false
  }
  render() {
    const { download, display, setGallery, files, deleteFile, category } = this.props
    const { selected, list } = this.state
    if(category && !display) return null
    return (
      <div className={`docsgrid ${display?'visible':'hidden'}`}>
        {category &&
          <div className="file">
            <div onClick={() => this.setState({ selected: files })} className={`checkbox ${files && selected.length===files.length?'active':'inactive'}`}></div>
            <span className="filetype"><img src="/images/files/FolderGray.svg" alt="" /></span>
            <span className="name">Name</span>
            <span className="date">Date</span>
            <span className="uploaded">Uploaded by</span>
            <img className="fileoptions" onClick={(e) => console.log("hola")} src={'/images/basic/ThreePointsGray.svg'} alt="more options" />
          </div>
        }
        {files && files.map((file, index) =>
          <div key={index} onClick={(e) => {e.stopPropagation();category?setGallery(true):file.progress && file.progress < 100 ? newLive('/images/logos/Logo.svg', 'Preview', ' is not available yet'):setGallery(true)}} className="file">
            <div className={`checkbox ${selected.filter(s=>s.id === file.id).length>0?'active':'inactive'}`}></div>
            <img className="filetype" src={srcType(file && file.type)} alt="Icon File Type" />
            <span className="name" onClick={(e) => {e.stopPropagation();setGallery(true)}}>{capitalizeFirstLetter(file.name)}</span>
            <span className="date">{timeDiff(file.createdAt)}</span>
            {category && file.author &&
              <div className="uploaded">
                <img className="author" src={file.author.profileImg} alt="Author" />
                <span>{file.author.firstname + ' ' + file.author.lastname}</span>
              </div>
            }
            <img className="fileoptions" onClick={(e) => setGallery(true)} src={'/images/basic/ThreePointsGray.svg'} alt="more options" />
            {list === index &&
              <div onMouseLeave={() => this.setState({list: false})} style={{top: category && 60, right: category && 12}} className="quickmenu">
                <div className="delete" onClick={(e) => {e.stopPropagation();setGallery({file: file.id, files: files})}}><div><img src={'/images/basiced/OpenGray.svg'} alt="Open" /></div><span>Open</span></div>
                <div className="delete" onClick={(e) => {e.stopPropagation();download([file]);this.setState({list: false})}}><div><img src={'/images/basiced/DownloadGray.svg'} alt="Open" /></div><span>Download</span></div>
                <div className="delete" onClick={(e) => {e.stopPropagation();deleteFile([file]);this.setState({list: false})}}><div><img src={'/images/basiced/DeleteGray.svg'} alt="Open" /></div><span>Delete</span></div>
              </div>
            }
          </div>
        )}
        {category && files.length === 0 ?
          <div className="listempty">
            <img src={'/images/collages/Documents.svg'} alt="Drag and Drop" />
            <span>Looks like none files were uploaded</span>
          </div>
        :!category && Array(20-files.length).fill(null).filter((f,i) => category !== 'files' ? i < 6-files.length:true).map((empty, index) =>
          <div key={index} className="file empty">
            <img className="filetype" src="/images/files/Empty.svg" alt="Icon File Type" />
            <span className="name"></span>
            <span className="date"></span>
          </div>
        )}
        {selected.length > 0 && category === 'files' &&
          <div className="options">
            <div onClick={() => {download(selected);this.setState({ selected: [] })}} className="download">
              <img src={'/images/basic/DownloadWhite.svg'} alt="download" />
              <span>Download</span>
            </div>
            <div onClick={() => {deleteFile(selected);this.setState({ selected: [] })}} className="delete">
              <img src={'/images/basic/TrashWhite.svg'} alt="remove" />
              <span>Delete</span>
            </div>
          </div>
        }
      </div>
    )
  }
}
export default Documents
