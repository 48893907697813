import React, { Component } from 'react'
import Documents from '../basics/Documents'
import Media from '../basics/Media'
import UploadFile from '../basics/UploadFile'
import { formatImage, formatVideo, srcType } from 'js/utils'
import { files } from 'data2'

class Files extends Component {
  state = {
    tab: 'files'
  }

  render() {
    const { cproject, category, setGallery, selectCategory } = this.props
    const { tab } = this.state
    return (
      <div data-tour="files" className={`files-widget widget ${category ? 'full' : 'preview'}`}>
        <input multiple ref={(ref) => this.fileUpload = ref} onChange={this._onDrop} id="file-upload" type="file" hidden />
        <div className={category ? 'header' : 'headerclassic'}>
          <div className="title">
            <img src={'/images/basiced/Folder.svg'} alt="folder" />
            <span>Files</span>
          </div>
          <div className="selector">
            {category ?
              <span className="top" style={{marginLeft: tab === 'files' ? 0 : 165 }}></span>
            :
              <div className="bottom" style={{marginLeft: tab === 'files' ? 0 : 220 }}></div>
            }
            <div className={`tab ${tab === 'files' && 'selected'}`} onClick={() => this.setState({tab: 'files'})} >
              <img src="/images/basiced/Folder.svg" alt="" />
              <span>Uploaded Files</span>
            </div>
            <div className={`tab ${tab === 'media' && 'selected'}`} onClick={() => this.setState({tab: 'media'})} >
              <img src="/images/basiced/Media.svg" alt="" />
              <span>Shared Media</span>
            </div>
          </div>
          {category !== 'files' && <div className="whiteButton" onClick={() => setGallery(true)}>See All Media<img className="go" src="/images/basic/RightArrowGray1.svg" alt="go" /></div>}
          {category === 'files' && <div className="whiteButton" onClick={() => this.fileUpload.click()}>Upload Files <img className="go" style={{height: 10}} src="/images/basic/upyellow.svg" alt="go" /></div>}
        </div>
        <div className="body" style={{overflow: category === 'files' && 'auto'}}>
          <Documents display={tab==='files'} setGallery={setGallery} files={files && files.filter(f => !f.type.includes('image') && !f.type.includes('video')).filter((f,i)=>category !== 'files' ? i<6 : true)} category={category} />
          <Media display={tab==='media'} setGallery={setGallery} files={files && files.filter(f => f.type.includes('image') || f.type.includes('video'))} category={category} />
          {category !== 'files' && <UploadFile setModal={setGallery} type={tab} />}
        </div>
      </div>
    )
  }
}
/*
*/
export default Files
