import React, { Component } from 'react'
import { timeDiff } from 'js/utils'
import { notifications } from 'data'
const offset = 10

class LatestUpdatesSub extends Component {
  state = {
    loadMore: false
  }
  render() {
    const not = notifications.filter(n=>!n.read).length > 19 ? '+20' : notifications.filter(n=>!n.read).length
    return (
      <div id="latestupdates" data-tour="latestupdates" className="latestupdates widget" style={{maxWidth: 635}}>
        <div className="header">
          <div className="title">
            <img style={{ height: 22 }} src={'/images/basiced/LatestUpdates.svg'} alt="updates" />
            <span>Latest Updates</span>
          </div>
          <span className={`badge ${not===0?'off':''}`}>{not}</span>
        </div>
        <div className="body full">
          {notifications.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt)).map((notification, index) =>
            <div className="update" key={index}>
              <img className="user" src={notification.author && notification.author.profileImg} alt="User" />
              <div className="inner">
                <span className="bold" onMouseUp={(event) => console.log("User")}>{notification.author.firstname}</span>
                <span>{notification.description} </span>
              </div>
              {!notification.marked && <div className="unread"></div>}
              <div className="date">{timeDiff(notification.createdAt)}</div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default LatestUpdatesSub
