import React from 'react'
import ProfileBasic from './ProfileBasic'
import ChatList from './ChatList'

function RightBar({setModal, setChat, chat}) {
  return (
    <div className="rightBar">
      <ProfileBasic setModal={setModal} />
      <ChatList setModal={setModal} chat={chat} setChat={setChat} />
    </div>
  )
}
export default RightBar
