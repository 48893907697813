import React, { useState, useEffect, useRef } from 'react'
import { motion, useMotionValue } from "framer-motion"

const onTop = { zIndex: 2 };
const flat = {
//  zIndex: 1,
  transition: { delay: 0.3 }
};

export const Task = ({ setModal, task, delTask, modal, color, setPosition, i, dragItem, full, duplicateTask }) => {
  const [isDragging, setDragging] = useState(false);
  const [taskd, setTaskd] = useState();
  const [list, setList] = useState(false);

  // We'll use a `ref` to access the DOM element that the `motion.li` produces.
  // This will allow us to measure its height and position, which will be useful to
  // decide when a dragging element should switch places with its siblings.
  const ref = useRef(null);

  // By manually creating a reference to `dragOriginY` we can manipulate this value
  // if the user is dragging this DOM element while the drag gesture is active to
  // compensate for any movement as the items are re-positioned.
  const dragOriginX = useMotionValue(0);
  const dragOriginY = useMotionValue(0);
  // Update the measured position of the item so we can calculate when we should rearrange.
  useEffect(() => {
    setPosition(i, {
      height: ref.current.offsetHeight,
      top: ref.current.offsetTop,
      width: ref.current.parentNode.offsetWidth,
      left: ref.current.offsetLeft
    });
    //console.log("task", task)
  }, [task, i, setPosition]);
  return (
    <motion.div
      ref={ref}
      initial={false}
      onClick={() => {!isDragging && modal('viewTask', task);setDragging(false)}}//}
      // If we're dragging, we want to set the zIndex of that item to be on top of the other items.
      animate={isDragging ? onTop : flat}
      //whileHover={{ scale: 1.03 }}
      //whileTap={{ scale: 1.12 }}
      drag
      dragOriginX={dragOriginX}
      dragOriginY={dragOriginY}
      dragConstraints={{ top: 0, bottom: 0, left: 0, right: 0  }}
      dragElastic={1}
      onDragStart={(e) => setDragging(true)}
      onDragEnd={(e, {point}) => dragItem(task, i, point.x)}
      positionTransition={({ delta }) => {
        if (isDragging) {
          // If we're dragging, we want to "undo" the items movement within the list
          // by manipulating its dragOriginY. This will keep the item under the cursor,
          // even though it's jumping around the DOM.
        //  dragOriginX.set(dragOriginX.get() + delta.x);
          dragOriginY.set(dragOriginY.get() + delta.y);
        }

        // If `positionTransition` is a function and returns `false`, it's telling
        // Motion not to animate from its old position into its new one. If we're
        // dragging, we don't want any animation to occur.
        return !isDragging;
      }}
      className="task">
      {taskd &&
        <div className="confirmation">
          {taskd.logo ?
            <img className="logo" src={taskd.logo} alt="User" />
          :
            <div className="logo" style={{ background: taskd.color }}>
              {taskd.letter}
            </div>
          }
          <span className="name">{taskd.name}</span>
          <span className="description">Are you sure you want to delete this task?</span>
          <div className="buttons">
            <div className="whiteButton" onMouseUp={(e) => {e.stopPropagation();setTaskd(null)}}>Cancel</div>
            <div className="redButton" onMouseUp={(e) => {e.stopPropagation();delTask(taskd.id)}}><img src="/images/basic/TrashWhite.svg" alt="trash" />Delete</div>
          </div>
        </div>
      }
      <div className="top">
        <span className={`tag ${task.logo ? '' : 'preview'}`} style={{background: !task ? '#ff4570' : task.state==='backlog'?'#ff4570':task.state==='inprogress'?'#0FC8EF':task.state==='reviewing'?'#ffcc4c':'#43D8B3'}}>{task.state}</span>
        {list === i &&
          <div onMouseLeave={() => setModal(true)} className="quickmenu">
            <div onMouseUp={(e) => {e.stopPropagation();modal('viewTask', task)}}><div><img src={'/images/basiced/OpenGray.svg'} alt="Open" /></div><span>Open</span></div>
            <div onMouseUp={(e) => {e.stopPropagation();duplicateTask(task.id)}}><div><img src={'/images/basiced/DuplicateGray.svg'} alt="Open" /></div><span>Duplicate</span></div>
            <div onMouseUp={(e) => {e.stopPropagation();modal('viewTask', task)}}><div><img src={'/images/basiced/EditGray.svg'} alt="Open" /></div><span>Edit</span></div>
            <div onMouseUp={(e) => {e.stopPropagation();setTaskd(task);setList(false)}}><div><img src={'/images/basiced/DeleteGray.svg'} alt="Open" /></div><span>Delete</span></div>
          </div>
        }
        <div className={`name ${task.logo ? '' : 'preview'}`}>{task.name}</div>
      </div>
      <div className="edit" onMouseUp={(e) => {e.stopPropagation();setModal(true)}}>
        <img src={'/images/basic/ThreePointsGray.svg'} alt="Expand Task" />
      </div>
      {task.logo &&
        <div className="picture">
          <img src={task.logo} alt="Task Logo" />
        </div>
      }
      <div className="bottom">
        <div className="users">
          {task.users.map((user, index) =>
            <img key={index} className="customized" src={user.profileImg} alt="User" />
          )}
        </div>
        {task.length && (task.length.weeks !== 0 || task.length.days !== 0 || task.length.hours !== 0) &&
          <div className="deadline">
            <img src={'/images/basic/ClockGray.svg'} alt="Date" />
            <span>{task.length.weeks? `${task.length.weeks} weeks` : task.length.days ? `${task.length.days} days` : `${task.length.hours} hours`}</span>
          </div>
        }
      </div>
    </motion.div>
  )
}
