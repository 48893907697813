import React, { useState } from 'react'

export function InputText({ errore, value, onChange, placeholder, type, error, readonly, onBlur }) {
  const [focus, setFocus] = useState(false);
  return (
    <div className="textInput">
      {errore && <div className="errortag">{error}</div> }
      <input
        readOnly={readonly}
        onFocus={() => setFocus(true)}
        onBlur={() => {setFocus(false);onBlur && onBlur()}}
        autoComplete="new-password"
        value={value}
        onChange={onChange}
        type={type ? type : 'text'}
        placeholder={placeholder} />
    </div>
  )
}
export function InputTextProject({ errore, value, onChange, placeholder, type, error }) {
  const [focus, setFocus] = useState(false);
  return (
    <div className="projectInput">
      {errore && <div className="errortag">{error}</div> }
      <input
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        autoComplete="new-password"
        value={value}
        onChange={onChange}
        type={type ? type : 'text'}
        placeholder={placeholder} />
    </div>
  )
}
