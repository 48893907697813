import React, { Component } from 'react'
import { sortmessages } from 'js/utils'
import { stories } from 'data'

class StoriesSub extends Component {
  state = { sts: 0, src: '/images/basic/AddGreen.svg' };

  _updateDimensions = () => {
    this.sts && this.setState({ sts: Math.round((this.sts.clientWidth-50)/62) });
  }

  _startDimensions = () => {
    window.addEventListener('resize', this._updateDimensions);
    this._updateDimensions();
  }

  componentDidMount(){
    this._startDimensions()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._updateDimensions);
  }

  render() {
    const { setModal } = this.props
    const { src } = this.state
    return (
      <div data-tour="stories" className={`stories-widget widget preview`}>
        <div className="header">
          <div className="title">
            <img src={'/images/basiced/Stories.svg'} alt="stories" />
            <span>Stories</span>
          </div>
          <span className={`badge ${stories.length===0?'off':''}`}>{stories.length}</span>
        </div>
        <div className="body" ref={sts => this.sts = sts}>
          <div onClick={() => setModal(true)} onMouseOver={() => this.setState({src: '/images/basic/AddDark.svg'})} onMouseLeave={() => this.setState({src: '/images/basic/AddGreen.svg'})} className="story">
            <img src={src} alt="New Story" />
          </div>
          {stories && stories.sort(sortmessages).map((story, index) =>
            <div className={`story ${story.view ? 'viewed' : "unviewed"} `} onClick={() => setModal(true)} key={index}>
              <img src={story.author.profileImg} alt="User" />
            </div>
          )}
          {stories.length < this.state.sts && Array(this.state.sts-stories.length).fill(null).map((story, index) =>
            <div className="story none" key={index}>
              <img src={'/images/basiced/Story.svg'} alt="User" />
            </div>
          )}
        </div>
      </div>
    )
  }
}
export default StoriesSub
