import React, { useEffect, useState } from 'react';
import Leftbar from './components/Leftbar'
import RightBar from './components/RightBar'
import Topbar from './components/Topbar'
import Registration from './components/Registration'
import Mainscreen from './components/Mainscreen/Mainscreen'
import { screenED2 } from 'js/constants'

function App() {
  const [modal, setModal] = useState(false);
  const [chat, setChat] = useState(null);
  const [transform, setTransform] = useState(null);

  const handleResize = () => document.body.clientWidth < screenED2 && setTransform(document.body.clientWidth/screenED2)

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize);
  }, [document.body.clientWidth]);

  return (
    <div className="App" style={{zoom: transform}}>
      <Mainscreen setModal={setModal} />
      <Leftbar setModal={setModal} />
      <RightBar setModal={setModal} chat={chat} setChat={(data) => setChat({ chat, ...data })} />
      <Topbar setModal={setModal} />
      {modal && <Registration setModal={setModal} /> }
      <div id="livenoti" className="livenoti"></div>
    </div>
  );
}

export default App;
