import React, { Component } from 'react'
//import ConsultFull from '../basics/ConsultFull'
import Consult from '../basics/Consult'
import { consults } from 'data'

class Consultancy extends Component {
  state = {
    description: '',
    search: '',
    view: false
  }
  render() {
    const { setModal, category } = this.props
    return (
      <div data-tour="consults" className={`consult-widget widget ${category ? 'full' : 'preview'}`}>
        <div className="header">
          <div className="title">
            <img src={'/images/basiced/Quiz.svg'} alt="quiz" />
            <span>Consults</span>
          </div>
          {category ?
            <div className="whiteButton" onClick={() => setModal(true)}>
              <img src={'/images/basic/AddYellow.svg'} alt="add" /> Add Consult
            </div>
          :
            <div className="whiteButton" onClick={() => setModal(true)}>See All Consults<img className="go" src="/images/basic/RightArrowGray1.svg" alt="go" /></div>
          }
        </div>
        <div className="body">
          {!category &&
            <>
              <div className="consult">
                <img src={'/images/collages/AskQuestion.svg'} alt="Ask Question" />
                <div onClick={() => setModal(true)} className="blueButton">
                  <div className="whiteCircle"><img src="/images/basic/AddBlue.svg" alt="" /></div>
                  Add Question
                </div>
              </div>
              {consults.sort((a,b) => { return new Date(b.createdAt) - new Date(a.createdAt)}).filter((c,i) => i < 3).map((consult, index) =>
                <Consult key={index} setModal={setModal} consult={consult} />
              )}
              {consults.length <3 && Array(3-consults.length).fill(null).map((f, index) =>
                <Consult key={index} pakete />
              )}
            </>
          }
          {/*category &&
            <div className="fullbody">
              {consults.sort((a,b) => { return new Date(b.createdAt) - new Date(a.createdAt)}).map((consult, index) =>
                <ConsultFull key={index} modal={modal} consult={consult} myself={myself} />
              )}
              {consults.length < 4 && Array(4-consults.length).fill(null).map((f, index) =>
                <ConsultFullEmpty />
              )}
            </div>
           */}
        </div>
      </div>
    )
  }
}
export default Consultancy
