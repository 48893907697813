import React, { Component } from 'react'
import ElementChatList from './ElementChatList'
import { sortchat2 } from 'js/utils'
import { privats, inquiries } from 'data'

class PrivateList extends Component {
  state = {
    search: ''
  }
  render() {
    const { setChat, setModal, tab, chat, userId } = this.props
    const { search } = this.state
    if(!tab) return null
    return (
      <div className="chatList">
        <div className="search">
          <img src="/images/basic/SearchGray.svg" alt="Search" />
          <input placeholder="Search..." value={search} onChange={(e) => this.setState({ search: e.target.value })} />
        </div>
        <div className="list" data-tour="inquiries">
          <div className="head"><img src="/images/basic/InquiryGray.svg" alt="Inquiery" />Inquiries</div>
          {inquiries && inquiries.filter(inq => inq.name.toLowerCase().includes(search.toLowerCase())).sort(sortchat2).map((inquiry, index) =>
            <ElementChatList key={inquiry.id} selected={chat && inquiry.id === chat.inquiry} setChat={() => setModal(true)} element={inquiry} />
          )}
          {/*account === 'freelancer' && inquiries.length === 0 &&
            <div className="empty">
              <img src="/images/collages/EmptyInquiries.svg" alt="Inquiries" />
              <span>Look's like you don't have any inquiries yet</span>
            </div>
          */}
          <div className="head"><img src="/images/basic/ChatGray.svg" alt="Chat" />Chats</div>
          {privats.filter(privat => privat.name && privat.name.toLowerCase().includes(search.toLowerCase())).sort(sortchat2).map((privat, index) =>
            <ElementChatList key={privat.id} selected={chat && privat.id === chat.privat} setChat={() => setModal(true)} element={privat} />
          )}
          {privats.length === 0 &&
            <div className="empty">
              <img src="/images/collages/EmptyChats.svg" alt="Chat" />
              <span>Look's like you don't have any conversations yet</span>
            </div>
          }
        </div>
      </div>
    )
  }
}
export default PrivateList
