import React, {Component} from 'react'
import Notifications from './Notifications'
import { projects } from 'data'

class Topbar extends Component {
  state = {
    create: false
  }

  componentDidMount(){
    document.onkeydown = (evt) => {
      evt = evt || window.event;
      if (evt.keyCode === 27) this.setState({ create: false })
    }
    document.addEventListener('click', (e) => this.create && !this.create.contains(e.target) && this.setState({create: false}))
  }

  render() {
    const { setModal } = this.props
    const { create, list } = this.state
    const selected = projects[0]
    return (
      <div className="topBar">
        <div className="logo">
          <img src={'/images/logos/Logo.svg'} alt="" />
        </div>
        <div className="projects">
          <div data-tour="projects" className="project" onClick={() => this.setState({list: true})}>
            {selected.logo ?
              <img className="logo" src={selected.logo} alt="" />
            :
              <div className="logo" style={{ background: selected.color }}>
                {selected.name.length > 0 ? selected.name.substring(0,1) : 'P'}
              </div>
            }
            <span>{selected.name}</span>
            <img className="select" src={'/images/basic/DownArrowGray.svg'} alt="" />
          </div>
          {list &&
            <div className="projectsList" onMouseLeave={() => this.setState({list: false})}>
              {projects && projects.map((project, index) =>
                <div onClick={() => this.setState({list: false})} key={index}>
                  {project.logo ?
                    <img className="logo" src={project.logo} alt="" />
                  :
                    <div className="logo" style={{ background: project.color }}>
                      {project.name.length > 0 ? project.name.substring(0,1) : 'P'}
                    </div>
                  }
                  <span>{project && project.name}</span>
                  <img className="edit" onClick={() => setModal(true)} src={'/images/basic/SettingsGray.svg'} alt="Edit" />
                </div>
              )}
              <div onClick={() => {this.setState({list: false});setModal(true)}}>
                <img className="logo" src={'/images/basiced/plus.svg'} alt="" />
                <span>Create New project</span>
              </div>
            </div>
          }
        </div>
        <div className="invite" onClick={() => setModal(true)}>
          <div className="whiteCircle">
            <img src="/images/basic/AddBlue.svg" alt="" />
          </div>
          <span>Invite your team</span>
        </div>
        <Notifications setModal={setModal} />
        <img className="create" ref={r=>this.create=r} onClick={() => this.setState({create: !create})} src={'/images/basiced/AddEd.svg'} alt="Create" />
        {create &&
          <div className="menuCreate" onMouseLeave={() => this.setState({create: false})}>
            <div onClick={() => setModal(true)}><img style={{height: 24}} src={'/images/basiced/ProjectOutline.svg'} alt="" />Create New Project</div>
            <div onClick={() => setModal(true)}><img style={{height: 24}} src={'/images/basiced/TaskOutline.svg'} alt="" />Create New Task</div>
            <div onClick={() => setModal(true)}><img style={{height: 22}} src={'/images/basiced/StoryOutline.svg'} alt="" />Create New Story</div>
            <div onClick={() => setModal(true)}><img style={{height: 22}} src={'/images/basiced/ConsultOutline.svg'} alt="" />Create New Consult</div>
          </div>
        }
      </div>
    )
  }
}
export default Topbar
