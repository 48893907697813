import React, { Component } from 'react'
import { newLive } from 'js/utils'
import { InputText } from 'js/components'
import { validateEmail } from 'js/utils'

class Info extends Component {
  state = {
    invite: false,
    email: '',
    errore: { email: false },
  };

  render() {
    const { category, setModal } = this.props
    const { invite, email, errore, accepted } = this.state
    if(category) return null
    return (
      <div data-tour="promotions" className="promotions-widget widget">
        <div className="header">
          <div className="title">
            <img src={'/images/basiced/Promos.svg'} alt="promos" />
            <span style={{marginBottom: -5}}>Special Promotions</span>
          </div>
          <div className="buttons">
            <img src={'/images/basic/LeftArrowBlack.svg'} onClick={() => console.log("previous")} alt="More Emails" />
            <img src={'/images/basic/RightArrowBlack.svg'} onClick={() => console.log("next")} alt="More Emails" />
          </div>
        </div>
        <div className="body">
          {!invite ?
            <div className="left">
              <span className="title">Get <span>1000 Euros</span> in work of our freelancers</span>
              <span className="description">Get 10 Euros for each member you invite to the platform! Offer limited up to 100 members</span>
              <div className="buttons">
                <div className="whiteButton" style={{width: 185}} onClick={() => this.setState({ invite: true })}><img src="/images/basiced/AddEdThin.svg" alt="add edworking" />  Invite your contacts </div>
                <div className="whiteButton" style={{width: 215}} onClick={() => setModal(true)}><img src="/images/basic/Link.svg" alt="add edworking" /> Copy invitation link </div>
              </div>
            </div>
          :
            <div className="left invite">
              <img className="close" onClick={() => this.setState({invite: false})} src={'/images/basic/CloseGray.svg'} alt="close" />
              <span className="title">Invite your <span>Contacts</span></span>
              <span className="description">Enter one of your contact's email below and send the invitation to get 10€ for every contact</span>
              <div className="buttons">
                <InputText errore={errore.email}  value={email} onChange={e => this.setState({ email: e.target.value })} placeholder="Enter your email" type="email" error={errore.email===true?"Enter your email":errore.email} />
                <div className="whiteButton" onClick={() => setModal(true)}>{accepted ? 'Invited!' : 'Invite'}</div>
              </div>
            </div>
          }
          <div className="right">
            <div className="email">
              <div className="promo"><img  src="/images/basiced/Balance.svg" alt="promos" /></div>
              <span className="text">Bonus Balance</span>
              <span className="money">{(20).toLocaleString("en-US", {style:"currency", currency: 'GBP', minimumFractionDigits: 0, maximumFractionDigits: 0})}</span>
            </div>
            <div className="email">
              <span className="initial">d</span>
              <span className="text">david.remnick@edworking.com</span>
              <div className="prom">{(10).toLocaleString("en-US", {style:"currency", currency: 'GBP', minimumFractionDigits: 0, maximumFractionDigits: 0})}</div>
            </div>
            <div className="email">
              <span className="initial">e</span>
              <span className="text">estefania.carlton@edworking.com</span>
                <div className="prom pending">Pending</div>
            </div>
            <img src="/images/empty/Promotion.svg" alt="promotion empty" />
          </div>
        </div>
      </div>
    )
  }
}
export default Info
