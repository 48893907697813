import React, { Component } from 'react'
import TasksStates from '../basics/TasksStates'
import { TasksLoading } from 'js/empty'
import { newLive } from 'js/utils'
import { tasks } from 'data'

class Tasks extends Component {
  state = {
    taskss: []
  }
  _moveState = (newtask) => {
    this.setState({taskss: this.state.taskss.reduce((tasks, task, index) => {
      return task.id === newtask.id ? tasks.concat({...newtask, updatedAt: new Date()}) : tasks.concat(task)
    }, [])})
    //newLive('/images/logos/Logo.svg', newtask.name,  ' was succesfully moved to ' + newtask.state)
  }
  componentDidMount(){
    this.setState({taskss: tasks})
  }
  _duplicateTask = (task) => {
    this.setState({taskss: this.state.taskss.concat(task)})
    newLive('/images/logos/Logo.svg', 'Task ', 'was successfully duplicated with the same state.')
  }

  _delTask = (newtask) => {
    this.setState({taskss: this.state.taskss.filter(f=>f.id!==newtask.id)})
    newLive('/images/logos/Logo.svg', 'Task ', 'was removed.')
  }

  render() {
    const { cproject, modal, states, category, setModal } = this.props
    if(category && category !== 'tasks') return null
    return (
      <div id="tasks" data-tour="tasks" className={`tasks-widget widget ${category ? 'full' : ''}`}>
        <div className="header">
          <div className="title">
            <img src={'/images/basiced/Tasks.svg'} alt="tasks" />
            <span>Tasks</span>
          </div>
          {category ?
            <div className="whiteButton" onClick={() => setModal(true)}>
              <img src={'/images/basiced/plus.svg'} alt="create"/> Create New Task
            </div>
          :
            <div className="whiteButton" onClick={() => setModal(true)}>See All Tasks<img className="go" src="/images/basic/RightArrowGray1.svg" alt="go" /></div>
          }
        </div>
        <div className="body">
          {states.map((state, index) =>
            <TasksStates setModal={setModal} goFull={() => setModal(true)} duplicateTask={this._duplicateTask} delTask={this._delTask} fullview={category} key={index} state={state} states={states} moveState={this._moveState} initialTasks={this.state.taskss.filter(t => t.state === state)} modal={modal} />
          )}
        </div>
      </div>
    )
  }
}
export default Tasks
