import React, { Component } from 'react'

class Leftbar extends Component {
  state = {
    hover: null,
  }
  render() {
    const { setModal } = this.props
    const { hover } = this.state
    return (
      <div className="leftBar">
        <span className="shadow" style={{marginTop: hover === 0 ? 10 : hover === 1 ? 102 : hover === 2 ? 194 :hover === 3 ? 286 :hover === 4 ? 378 :hover === 5 ? 470: hover === 6 ? 562 : -200 }}></span>
        <div className={'wid'} onMouseOver={()=>this.setState({hover: 0})} onMouseLeave={()=>this.setState({hover: null})} onClick={() => setModal(true)}>
          <img style={{ width: 20 }} src={'/images/basiced/Home.svg'} alt="" />
          <span>Home</span>
        </div>
        <div className={'wid'} onMouseOver={()=>this.setState({hover: 1})} onMouseLeave={()=>this.setState({hover: null})} onClick={() => setModal(true)}>
          <img style={{ width: 22 }} src={'/images/basiced/Tasks.svg'} alt="" />
          <span>Tasks</span>
        </div>
        <div className={'wid'} onMouseOver={()=>this.setState({hover: 2})} onMouseLeave={()=>this.setState({hover: null})} onClick={() => setModal(true)}>
          <img style={{ width: 26 }} src={'/images/basiced/Folder.svg'} alt="" />
          <span>Files</span>
        </div>
        <div className={'wid'} onMouseOver={()=>this.setState({hover: 3})} onMouseLeave={()=>this.setState({hover: null})} onClick={() => setModal(true)}>
          <img style={{ width: 22 }} src={'/images/basiced/Quiz.svg'} alt="" />
          <span>Consults</span>
        </div>
        <div className={'wid'} onMouseOver={()=>this.setState({hover: 4})} onMouseLeave={()=>this.setState({hover: null})} onClick={() => setModal(true)}>
          <img style={{ width: 33 }} src={'/images/basiced/EdWorkers.svg'} alt="" />
          <span>EdWorkers</span>
        </div>
        <div className={'wid'} onMouseOver={()=>this.setState({hover: 5})} onMouseLeave={()=>this.setState({hover: null})} onClick={() => setModal(true)}>
          <img style={{ width: 23 }} src={'/images/basiced/Workspace.svg'} alt="" />
          <span>Workspace</span>
        </div>
        <div className={'wid'} onMouseOver={()=>this.setState({hover: 6})} onMouseLeave={()=>this.setState({hover: null})} onClick={() => setModal(true)}>
          <img style={{ width: 26 }} src={'/images/basiced/Meetings.svg'} alt="" />
          <span>Meetings</span>
        </div>
      </div>
    )
  }
}
export default Leftbar
