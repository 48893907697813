import React, { Component } from 'react'

class Meetingroom extends Component {
  render() {
    const { category, setModal } = this.props
    if(category && category !== 'meetingroom') return null
    return (
      <div data-tour="meetingroom" className="meets-widget widget">
        <div className="header">
          <div className="title">
            <img style={{height: 22}} src={'/images/basiced/Meetings.svg'} alt="meetingroom" />
            <span>Meeting Room</span>
          </div>
        </div>
        <div className="body">
          <img src={'/images/collages/Meetingroom.svg'} alt="Meeting room" />
          <div className="blueButton" onClick={() => setModal(true)}>
            Start a Meeting
            <img className="go" src="/images/basic/RightArrowWhite2.svg" alt="go" />
          </div>
        </div>
      </div>
    )
  }
}
export default Meetingroom
