import React, { Component } from 'react'
import Edworker from '../basics/Edworker'
import { edworkers } from 'data'

class Edworkers extends Component {
  state = {
    filter: false
  }
  render() {
    const {  setModal, category } = this.props
    const { filter } = this.state
    if(category && category !== 'edworkers') return null
    return (
      <div data-tour="edworkers" className={`edworkers-widget widget ${category ? 'full' : 'preview'}`}>
        <div className="alledworkers">
          <div className="header">
            <div className="title">
              <img style={{ height: 18 }} alt="Three colorful edworkers" src={'/images/basiced/EdWorkers.svg'} />
              <span>EdWorkers</span>
            </div>
            {category !== 'edworkers' && <div className="whiteButton" onClick={() => setModal(true)}>See All EdWorkers<img className="go" src="/images/basic/RightArrowGray1.svg" alt="go" /></div>}
            {category === 'edworkers' &&
              <div className="filter" onClick={() => this.setState({filter: !filter})}>
                <span>Click to open filters</span>
                <img alt="Open options to filter" src={'/images/basiced/Filter.svg'} />
              </div>
            }
          </div>
          <div className="body">
            {edworkers && edworkers.map((edworker, index) =>
              <Edworker setModal={setModal} key={index} edworker={edworker} />
            )}
          </div>
        </div>
      </div>
    )
  }
}
export default Edworkers
