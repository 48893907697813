import React, { Component } from 'react'
import { edcolor } from 'js/constants'

class Edworker extends Component {
//onClick={(e) => {e.stopPropagation();setProfile && setProfile(edworker)}
  render() {
    const { edworker, index, setModal } = this.props
    const tech = edworker.techs.reduce((max, tech) => { return tech.level > max.level ? tech : max }, {level: 0})
    return (
      <div className="edworker" onMouseUp={(e) => setModal(true)}>
        <div className="leftbar" style={{ background: edcolor(index%4) }} />
        <div className="invite">
          <img className="create" src={'/images/basiced/AddEdThin.svg'} alt="Create" />
        </div>
        <img className="author" src={edworker.profileImg} alt="Author" />
        <div className="tech">
          <img src={tech.url} alt="Author" />
        </div>
        <div className="info" >
          <span className="name">{edworker.firstname + ' ' + edworker.lastname}</span>
          <span className="tech">Expert in {tech.name}</span>
        </div>
        <div className="edbottom">
          <span className="efficiency" style={{color: edworker.efficacy === 0 && '#09323A40'}}><img src="/images/basiced/Efficiency.svg" alt="effuciency"/>{edworker.efficacy === 0 ? '0.0' : edworker.efficacy/10 }</span>
          <span className="rate">{edworker.rate && edworker.rate.toLocaleString("en-US", {style:"currency", currency: edworker.currency, minimumFractionDigits: 0, maximumFractionDigits: 0})} /day</span>
        </div>
      </div>
    )
  }
}
export default Edworker
