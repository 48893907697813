import React, { Component } from 'react'
import { retrieveImageFromClipboardAsBlob, formatImage, formatVideo } from 'js/utils'

class UploadFile extends Component {
  state = {
    classDrag: 'deactivate',
    comment: ''
  }
  render() {
    const { type, setModal } = this.props
    const { classDrag } = this.state
    return (
      <div
        className={`dragdrop ${classDrag}`}>
        <img style={{ marginBottom: type==='media' && -8}}src={type==='files'?'/images/collages/Documents.svg':'/images/collages/Media.svg'} alt="Drag and Drop" />
        <span>{type==='files'?'Drag and drop documents and other files with your team':'Drag and drop images and videos with your team'}</span>
        <div className="buttons">
          <div onClick={(e) => setModal(true)} className="blueButton">
            <img src={'/images/basic/UpArrowWhite.svg'} alt="Upload" />
            <span>Upload</span>
          </div>
        </div>
        <input multiple ref={(ref) => this.fileUpload = ref} accept={type!=='files' ? 'audio/*, video/*, image/*':'*'} id="file-upload" type="file" />
      </div>
    )
  }
}
export default UploadFile
