import React, { Component } from 'react'
import { typeNoti, timeDiff, newLive } from 'js/utils'
import { notifications } from 'data'
const offset = 20

class Notifications extends Component {
  state = {
    listnotifications: false,
    loadMore: false
  }

  componentDidMount(){
    document.onkeydown = (evt) => {
      evt = evt || window.event;
      if (evt.keyCode === 27) this.setState({ listnotifications: false })
    }
    document.addEventListener('click', (e) => this.noti && !this.noti.contains(e.target) && this.setState({listnotifications: false}))
  }
  render() {
    const { listnotifications } = this.state
    const { setModal } = this.props
    const not = 4

    return (
      <div ref={r=>this.noti=r} className="notifications">
        <div className="notification" onClick={() => this.setState({listnotifications: !this.state.listnotifications})}>
          <img src="/images/basic/NotificationBlack.svg" alt="Notifications" />
          <span>4</span>
        </div>
        {listnotifications &&
          <div className="notificationList">
            <div className="header">
              <span>Notifications</span>
              <div className={notifications.length===0 ? 'off' : ''}>{not}</div>
            </div>
            <div className="list">
              {notifications.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt)).map((notification, index) =>{
                const name = notification.author && notification.author.firstname + ' ' + (notification.author.lastname.match(/^(\S+)\s(.*)/) ? notification.author.lastname.match(/^(\S+)\s(.*)/).slice(1)[0] : notification.author.lastname)
                return(
                  <div className="notification" onMouseUp={()=> setModal()} key={index}>
                    <img src={notification.author && notification.author.profileImg} alt="User" />
                    <div className="center">
                      <div className="inner">
                        <span className="bold" onMouseUp={(event) => setModal()}>{name}</span>
                        <span>{notification.description} {notification.project ? ' in ' : ''} </span>
                        <span className="bold" onMouseUp={(event) => setModal()}>{notification.project ? notification.project.name : ''}</span>
                      </div>
                      <div className="date">{timeDiff(notification.createdAt)}</div>
                    </div>
                    {!notification.marked && <div className="unread"></div>}
                  </div>
                )
              })}
            </div>
            {notifications.length === 0 &&
              <div className="empty">
                <img src={'/images/collages/EmptyNotifications.svg'} alt="No notifications" />
                <span>Looks like you don't have any new notifications at the moment</span>
              </div>
            }
          </div>
        }
      </div>
    )
  }
}

export default Notifications
