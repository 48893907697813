import React, { Component } from 'react'
import hljs from 'highlight.js'
import 'highlight.js/styles/atom-one-dark.css'
import Quill from 'quill';
import { toolbarOptions } from 'js/constants'
import { WorkspaceLoading } from 'js/empty'
import { wall } from 'data'

class Workspace extends Component {
  constructor(props) {
    super(props)
    this.state = {
      description: '',
      change: false,
      saved: null
    }
    this.quillfull = null
  }

  componentDidMount() {
      this.quillfull = new Quill(this.quillf, {
        modules: {
          syntax: {
            highlight: text => hljs.highlightAuto(text).value
          },
          toolbar: toolbarOptions,
          history: {
            delay: 2000,
            maxStack: 500,
            userOnly: true
          }
        },
        theme: 'snow'
      });
    this.quillfull.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
      delta.ops = delta.ops.map(op => {
        return op.insert.image ?{
          attributes: { width: "100%" },
          insert: op.insert
        }:{
          insert: op.insert
        }
      })
      return delta
    })

    if(wall.description && wall.description !== ''){
      this.quillfull.setContents(JSON.parse(wall.description))
      this.quillfull.on('text-change', (delta) => {
      //  if(category) this.quillprev.setContents(this.quillfull.getContents())
        if(!this.state.change) this.setState({change: true})
      })
    }
    //this._subscribeToDescription(this.subscribeToWall)
  }

  render() {
    const { category, setModal } = this.props
    if(category && category !== 'workspace') return null
    return (
      <div data-tour="workspace" className={`workspace widget ${category ? 'full': 'preview'}`}>
        <div className="header">
          <div className="title">
            <img src={'/images/basiced/Workspace.svg'} alt="workspace" />
            <span>Workspace</span>
          </div>
        </div>
        <div className="full" id="workspacewall" ref={(quill) => {this.quillf = quill}} />
        <div className="blueButton" onClick={() => setModal(true)}>See More<img src="/images/basic/RightArrowWhite2.svg" alt="see more" /></div>
      </div>
    )
  }
}

export default Workspace
