import React, { Component } from 'react'
//import hljs from 'highlight.js'
//import 'highlight.js/styles/atom-one-dark.css'
//import Quill from 'quill';

class Consult extends Component {
  /*

  componentDidMount(){
    const { consult, pakete } = this.props
    //if(pakete) return
    this.quilld = new Quill(this.quill, {
      modules: {
        syntax: {
          highlight: text => hljs.highlightAuto(text).value
        }
      },
      readOnly: true,
    });
    if(consult && consult.wall && consult.wall.description){
      const description = JSON.parse(consult.wall.description)
      const show = description.ops.reduce((ops, op, index) => {
        return typeof op.insert === "string" ? ops.concat({ insert: op.insert }) : ops
      }, [])
      this.quilld.setContents({ops: show})
    }
  }
  */

  render() {
    const { pakete, consult, setModal } = this.props
    if(pakete){
      return (
        <div className="shadowconsult">
          <div className="top">
            <img src={'/images/basic/UsersGray.svg'} alt="Users Icon" />
            <span>0 views</span>
          </div>
          <div className="middle">
            <div className="title" />
            <div className="body" />
            <div className="body" />
            <div className="body" />
          </div>
          <div className="bottom">
            <div className="authors">
            </div>
            <span>0</span>
            <img src={'/images/basic/CommentsGray.svg'} alt="Comments Icon" />
          </div>
        </div>
      )
    }
    else if(consult){
      const relusers = consult.answers.reduce((as, a) => {return a.author ? (as.filter(a2 => a2.id === a.author.id).length > 0 ? as : as.concat({ id: a.author.id, profileImg: a.author.profileImg })) : as}, [])
      return (
        <div onClick={() => setModal(true)} className="consult">
          <div className="top">
            <img src={'/images/basic/UsersGray.svg'} alt="Users Icon" />
            <span>{consult.views.length} views</span>
          </div>
          <div className="middle">
            <div className="title">{consult.name}</div>
            <div className="body">{consult.description}</div>
            {/*<div className="" ref={(quill) => {this.quill = quill}} />*/}
          </div>
          <div className="bottom">
            <div className="authors">
              {relusers.filter((a, i) => i < 4).map((u, index) =>
                <img key={index} src={u.profileImg} alt="Users Commented Icon" />
              )}
            </div>
            <span>{consult.answers ? consult.answers.length : 0}</span>
            <img src={consult.answers.length === 0 ? '/images/basic/CommentsGray.svg' : '/images/basic/CommentsColor.svg'} alt="Comments Icon" />
          </div>
        </div>
      )
    }
  }
}
export default Consult
