import React, { Component } from 'react'
import Tasks from './Tasks'
import Stories from './Stories'
import Files from './Files'
import Workspace from './Workspace'
import Promotions from './Promotions'
import Meetingroom from './Meetingroom'
import LatestUpdates from './LatestUpdates'
import Edworkers from './Edworkers'
import Consultancy from './Consultancy'

class Mainscreen extends Component {

  render() {
    const { setModal } = this.props
    return (
      <div className="mainScreen">
        <Tasks setModal={setModal} modal={setModal} states={['backlog', 'inprogress', 'reviewing', 'completed']} category={null} />
        <Stories setModal={setModal} category={null} />
        <Files setGallery={setModal} category={null} />
        <Consultancy setModal={setModal} category={null} />
        <Edworkers setModal={setModal} category={null} />
        <Workspace setModal={setModal} category={null} />
        <div className="meetlatest">
          <Meetingroom setModal={setModal} category={null}  />
          <LatestUpdates category={null} setModal={setModal} />
        </div>
        <Promotions setModal={setModal} category={null} />
      </div>
    )
  }
}
export default Mainscreen
