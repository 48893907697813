import React, { Component } from 'react'
import PrivateList from './PrivateList'

class ChatList extends Component {
  state = {
    tab: 'private',
    incoming: null,
    punread: 4,
    tunread: 0,
    iunread: 0
  }

  _setUnread = (data) => this.setState(data)

  render() {
    const { setChat, setModal, setVideocall, chat, account } = this.props
    const { tab, punread, tunread, iunread } = this.state
    return (
      <div className="classList">
        <div className="menu">
          <div className="bottom" style={{marginLeft: tab === 'private' ? 0 : tab === 'task' ? 246/3 : 2*246/3 }}></div>
          <div className={tab === 'private' ? 'select' : ''} onClick={() => setModal(true)}>Privates {iunread > 0 && <span className="notification"></span>}</div>
          <div className={tab === 'task' ? 'select' : ''} onClick={() => setModal(true)}>Tasks {tunread > 0 && <span className="notification"></span>}</div>
          <div className={tab === 'project' ? 'select' : ''} onClick={() => setModal(true)}>Projects {punread > 0 && <span className="notification"></span>}</div>
        </div>
        <PrivateList setModal={setModal} setChat={setChat} chat={chat} tab={tab === 'private'} />
      </div>
    )
  }
}
export default ChatList
