import React, { Component } from 'react'
import { Gothrough } from './Gothrough'

class Settings extends Component {
  state = {
    isTourOpen: false
  }
  _logout = () => {
    this.props.setModal()
  }

  render() {
    const { open, match, close, setModal } = this.props
    const { isTourOpen } = this.state

    return (
      <div className={`settings ${open ? 'open' : 'close'}`}>
        <div onClick={() => {setModal(true);close()}}>
          <img src={'/images/basic/SettingsGray.svg'} alt="Settings"/>
          <span>Edit Profile</span>
        </div>
        <Gothrough isTourOpen={isTourOpen} setOpen={() => this.setState({isTourOpen: false})} />
        <div onClick={() => this.setState({isTourOpen: true})}>
          <img src={'/images/basic/Tour.svg'} style={{ padding: 1 }} alt="tour" />
          <span>Tour Edworking</span>
        </div>
        <div onClick={() => {setModal(true);close()}}>
          <img src={'/images/basic/LogoutGray.svg'} style={{ padding: 2 }} alt="Logout" />
          <span>Logout</span>
        </div>
      </div>
    )
  }
}
export default Settings
