import React, { useState, useEffect, useRef } from 'react'
import { Task } from './Task'
import { capitalizeFirstLetter, maxLength } from 'js/utils'

const TasksStates = ({ setModal, goFull, initialTasks, modal, states, state, moveState, fullview, delTask, duplicateTask }) => {
  const [tasks, setTasks] = useState(initialTasks)
  const setPosition = (i, offset) => positions[i] = offset

  let positions = useRef([]).current;

  useEffect(() => {
    setTasks(initialTasks)
  }, [initialTasks])

  const dragItem = (task, i, dragOffset) => {
    const oldstate = states.findIndex((s) => s === task.state)
    const avanti = dragOffset > 0
    const shifts = Math.round((Math.abs(dragOffset) - positions[i].width/2) / positions[i].width)
    const newstate =  avanti ? oldstate + shifts : oldstate - shifts
    if(oldstate !== shifts) moveState({ ...task, state: states[shifts] })
  }
  const maxnumber = maxLength(tasks)
  return(
    <div className="state">
      <div className="stateName">
        <img src={`/images/basiced/${capitalizeFirstLetter(state.replace(/\s/g, ''))}.svg`} alt="state" />
        <span className="state">{state==='inprogress' ? 'In Progress' : state}</span>
        <span className="number">{tasks.length}</span>
      </div>
      {(!tasks || tasks.length === 0) &&
        <div className="whiteState">
          <img src={`/images/collages/${capitalizeFirstLetter(state.replace(/\s/g, ''))}.svg`} alt="state representation" />
          <span>There are no tasks in {state} at the moment</span>
        </div>
      }
      {tasks && tasks.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)).filter((t,i) => !fullview ? i < maxnumber : true).map((task, index) =>
        <Task setModal={setModal} modal={modal} duplicateTask={duplicateTask} delTask={delTask} dragItem={dragItem} setPosition={setPosition} i={index} key={task.id} task={task} />
      )}
      {!fullview && (tasks.length > maxnumber) &&
        <div className="archived task" onClick={() => goFull()}>
          <img src={`/images/basic/Archive.svg`} alt="archived tasks" />
          <span>In All Tasks</span>
          <span className="number">+{tasks.length-maxnumber}</span>
        </div>
      }
    </div>
  )
}

export default TasksStates
