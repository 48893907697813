import React, { Component } from 'react'
import { InputText } from 'js/components'
import { newLive, validateEmail, base64fromURL2 } from 'js/utils'
import { GoogleLogin } from 'react-google-login';

class Registration extends Component {
  state = {
    down: false,
    errore: { email: false }
  }
  componentDidMount(){
    document.onkeydown = (evt) => {
      evt = evt || window.event;
      console.log("Key", evt.keyCode)
      if (evt.keyCode === 27) this.props.setModal(false)
    }
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      window.AppleID.auth.init({
        clientId: "com.edworking.signin3", // This is the service ID we created.
        scope: "name email", // To tell apple we want the user name and emails fields in the response it sends us.
        redirectURI: 'https://app.edworking.com/signup/invite/value1/value2', // As registered along with our service ID
        usePopup: true, // Important if we want to capture the data apple sends on the client side.
      });
    })
    document.addEventListener('AppleIDSignInOnSuccess',(event) => {
      console.log("AppleIdSignInOnSuccess", event)
      this._responseApple(event.detail)
    })
    document.addEventListener('AppleIDSignInOnFailure',(event) => {
      console.log("AppleIdSignInOnFailure")
    })

  }
  handleClick = (e) => {
    if(e.target !== this.modal) return
    else this.props.setModal(false)
  }

  _checkEmail = async (email) => {
    if(!validateEmail(email)) return 'Email invalid'
    else{
      const res = await fetch('https://venusaur2.edworking.com/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ operationName: 'CheckEmailMutation', query: 'query CheckEmailMutation($email: String!) {checkEmail(email: $email) }', variables: {email} }),
      })
      const { data } = await res.json();
      return data.checkEmail !== 'no' ? 'Email already in use' : false
    }
  }

  _registrate = async (email) => {
    const checkEmail = await this._checkEmail(email)
    if(checkEmail){
      this.setState({ errore: { email: checkEmail } })
    }else{
      const res = await fetch('https://venusaur2.edworking.com/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ operationName: 'inviteUserEmail', query: 'mutation inviteUserEmail($email: String!) {inviteUserEmail(email: $email){ id } }', variables: {email: email} }),
      })
      const { data } = await res.json();
      window.location = `https://app.edworking.com/signup/user/u/${data.inviteUserEmail.id}`
    }
  }
  _responseApple = async (appleUser) => {
    const { loading } = this.state
    if(loading) return
    else this.setState({loading: true})
    this.setState({loading: true})
    if(appleUser){
      fetch('https://venusaur2.edworking.com/', {//
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ operationName: 'LoginApple', query: 'mutation LoginApple($token: String!) {loginApple(token: $token) { token } }', variables: {token: appleUser.authorization.id_token} }),
      }).then(res => res.json())
        .then(res => {
          document.cookie = "islogged=true;path=/;domain=edworking.com"
          if(res.data?.loginApple) window.location = `https://app.edworking.com/?token=${res.data.loginApple.token}`
        })
    }
  }
  _responseGoogle = async (googleUser) => {
    const { loading } = this.state
    if(loading) return
    else this.setState({loading: true})
    if(googleUser){
      console.log("googleUser", googleUser)
      /*
      const checkEmail = await this._checkEmail(googleUser.profileObj.email)
      if(checkEmail){
        newLive('/images/logos/Logo.svg', 'Google Login ', checkEmail)
        return
      }
      */
      fetch('https://venusaur2.edworking.com/', {//
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ operationName: 'LoginGoogle', query: 'mutation LoginGoogle($token: String!) {loginGoogle(token: $token){ token } }', variables: {token: googleUser.tokenId} }),
      }).then(res => res.json())
        .then(res => {
          document.cookie = "islogged=true;path=/;domain=edworking.com"
          if(res.data && res.data.loginGoogle) window.location = `https://app.edworking.com/?token=${res.data.loginGoogle.token}`
        })
    }
  }

  render() {
    const { email, errore } = this.state
    return (
      <div ref={r=>this.modal=r} onClick={this.handleClick} className="modal">
        <div className="registration">
          <div onClick={() => this.props.setModal(false)} className="close">
            <img src={'/images/basic/CloseRegistration.svg'} alt="Close" />
          </div>
          <div className="left">
            <img src="/images/basiced/Petals.png" alt="Petals people" />
            <div className="title">Sign up</div>
            <div className="description">for the most advance team work platform</div>
          </div>
          <div className="right">
            <img src="/images/logos/LogoShadow.png" alt="Edworking" />
            {/*<div className="title">Enter your email for quick registration in the system</div>*/}
            <InputText errore={errore.email} value={email} onChange={e => this.setState({ email: e.target.value })} placeholder="Enter your email" type="email" error={errore.email===true?"Enter your email":errore.email} />
            <div className="blueButton" onClick={() => this._registrate(email)}>Register New Account</div>
            <GoogleLogin
              className="googlebutton"
              clientId="116827135235-6j8kt5kesf5vk55557eiet0tisca4u11.apps.googleusercontent.com"
              buttonText="Sign in with Google"
              onSuccess={this._responseGoogle}
              onFailure={this._responseGoogle}
              cookiePolicy={'single_host_origin'} />
            <div id="appleid-signin" className="appleButton" data-width="300" data-height="44" data-color="black" data-border="true" data-border-radius="22"></div>
          </div>
        </div>
      </div>
    )
  }
}
export default Registration
