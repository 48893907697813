import React, { Component } from 'react'

class ElementChatList extends Component {
  state = {
    hover: false
  }
  render() {
    const { setChat, selected, element, userId, invitation } = this.props
    const { hover } = this.state
    const spring = {
      type: "spring",
      damping: 20,
      stiffness: 300
    };
    const unread = element.messages.filter(m => m.createdAt > element.updated).length
    return (
      <div layoutTransition={spring} style={{background: selected && '#F7F9FB' }} className="chat" onClick={() => setChat()}>
        {element.logo ?
          <img className="customized" src={element.logo} alt="User" />
        :
          <div className="logo" style={{ background: element.color }}>
            {element.letter}
          </div>
        }
        {
        }
        {invitation ?
          <div className="logop" style={{ background: element.color }}>
            <img style={{width: 9}} src="/images/basic/RocketWhite.svg" alt="rocket" />
          </div>
        : element.project && (element.project.logo ?
          <img className="customizedp" src={element.project.logo} alt="User" />
        :
          <div className="logop" style={{ background: element.project.color }}>
            {element.project.letter}
          </div>
        )}
        {/*privat && <span className="online" />*/}
        <div className="center">
          <span className="fullname">{element.name}</span>
          <span className="message" style={{fontWeight: unread > 0 && 'bold'}}>{element.messages.length > 0 ? element.messages[0].body : 'Waiting for the first message'}</span>
        </div>
        {unread > 0 && <span className="notification">{ unread }</span>}
      </div>
    )
  }
}
export default ElementChatList
